import React from 'react';

import mapModifiers from 'utils/functions';

export type Sizes =
  | '10x14'
  | '12x16'
  | '12x18'
  | '14x18'
  | '14x20'
  | '16x22'
  | '18x26'
  | '16x24'
  | '20x32'
  | '24x32'
  | '28x38';

export type TextStyle = (GeneralTextStyle | Sizes)[];
export interface ParagraphProps extends React.HtmlHTMLAttributes<HTMLParagraphElement> {
  modifiers?: TextStyle;
  type?: 'p' | 'span' | 'div';
  content?: string;
  isInline?: boolean;
}

const Paragraph: React.FC<ParagraphProps> = ({
  modifiers,
  type = 'p',
  content,
  children,
  isInline,
  ...props
}) => {
  const Element = type;
  return (
    content ? (
      <Element
        className={mapModifiers('a-paragraph', modifiers, isInline && 'inline')}
        dangerouslySetInnerHTML={{ __html: content.replace(/<img([^>]*)\ssrc=(['"])(\/[^\2*([^\2\s<]+)\2/gi, `<img$1 src=$2${process.env.REACT_APP_BASE_URL || ''}$3$2`) }}
        {...props}
      />
    ) : (
      <Element className={mapModifiers('a-paragraph', modifiers, isInline && 'inline')} {...props}>
        {children}
      </Element>
    )
  );
};

Paragraph.defaultProps = {
  modifiers: undefined,
  type: 'p',
  content: undefined,
  isInline: undefined,
};

export default React.memo(Paragraph);
